export const API_DATA_CODE_RESPONSE = {
    SUCCESS: 0,
    NOTFOUND: 404,
    ERROR: 999
} as const

export const API_DATA_MESSAGE_RESPONSE = Object.freeze(  {
    SUCCESS: "Thành công.",
    SIGNIN_NOT_FOUND: "Signin not found!"
})

export const MESSAGE_MODAL_VI = {
    //login message
    LOGIN_SUCCESS : 'Đăng nhập thành công!',
    LOGIN_FAIL : 'Đăng nhập thất bại!',
    SIGNIN_NOT_FOUND: 'Sai thông tin đăng nhập!',

    //page message
    PAGE_NOT_FOUND : 'Không tìm thấy trang!',
    API_NOT_FOUND : 'API không tồn tại!',

    DELETE_SUCCESS : 'Xóa thành công!'
} as const

export const PaymentTypeOptions = [
    {
        value: "DEPARTURE_DATE",
        label: "Trước ngày khởi hành"
    },
    {
        value: "PAYMENT_DATE",
        label: "Sau ngày thanh toán lần 1"
    }
]

export const displayCurrency = '₫'

export enum TOUR_TYPE {
    DAILY,
    WEEKLY,
    SINGLE_DAY,
    MULTIPLE_DAY,
}

export const TourTypeOptions = [{
    value: TOUR_TYPE.DAILY,
    label: 'Hàng ngày'
},{
    value: TOUR_TYPE.WEEKLY,
    label: 'Hàng tuần'
},{
    value: TOUR_TYPE.SINGLE_DAY,
    label: 'Ngày'
},{
    value: TOUR_TYPE.MULTIPLE_DAY,
    label: 'Nhiều ngày'
}] as const

export enum WEEK_DAYS{
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

export const WeekDays = {
    [WEEK_DAYS.SUNDAY]: 'Chủ nhật',
    [WEEK_DAYS.MONDAY]: 'Thứ hai',
    [WEEK_DAYS.TUESDAY]: 'Thứ ba',
    [WEEK_DAYS.WEDNESDAY]: 'Thứ tư',
    [WEEK_DAYS.THURSDAY]: 'Thứ năm',
    [WEEK_DAYS.FRIDAY]: 'Thứ sáu',
    [WEEK_DAYS.SATURDAY]: 'Thứ bảy',
}

export const WeekDayOptions = [
    {value: WEEK_DAYS.SUNDAY, label: 'Chủ nhật'},
    {value: WEEK_DAYS.MONDAY, label: 'Thứ hai'},
    {value: WEEK_DAYS.TUESDAY, label: 'Thứ ba'},
    {value: WEEK_DAYS.WEDNESDAY, label: 'Thứ tư'},
    {value: WEEK_DAYS.THURSDAY, label: 'Thứ năm'},
    {value: WEEK_DAYS.FRIDAY, label: 'Thứ sáu'},
    {value: WEEK_DAYS.SATURDAY, label: 'Thứ bảy'},
] as const